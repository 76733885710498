.group_list button {
    cursor: pointer;
}

    .group_list button .MuiButton-endIcon {
        visibility: hidden;
    }

    .group_list button:hover .MuiButton-endIcon {
        visibility: visible;
    }

.data-view > .datatable-wrapper .title {
    display: none;
}



.datatable-wrapper .data-loading-text {
    animation: data-loading-text-delay 1s ease;
    animation-duration: 1s;
}

@keyframes data-loading-text-delay {
    from {
        display: none;
    }

    99% {
        display: none;
    }

    to {
        display: block;
    }
}