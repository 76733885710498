[data-emdtheme="mpt"] #emdroot-wrapper {
    flex-direction: column-reverse;
    padding: 0 2.5rem;
    background: #fff;
}

    [data-emdtheme="mpt"] #emdroot-wrapper * {
        /*color: #282828;*/
        font-family: Lato, Roboto, Helvetica, Arial, sans-serif;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .MuiPaper-root {
        background-color: transparent;
    }

/* Login */
[data-emdtheme="mpt"] #login-wrapper {
    background-image: url("mpt_login_bg.png");
    background-position-y: bottom;
    background-color: #000;
    background-repeat: no-repeat;
}

    [data-emdtheme="mpt"] #login-wrapper > form {
        width: 330px;
        gap: 1.5em;
    }

        [data-emdtheme="mpt"] #login-wrapper > form > .MuiInputBase-root,
        [data-emdtheme="mpt"] #login-wrapper > form > .MuiButtonBase-root {
            border-radius: 10px;
            box-shadow: 0px 4px 4px 0px #00000040;
        }

    [data-emdtheme="mpt"] #login-wrapper .MuiInputBase-root {
        background-color: transparent;
    }

        [data-emdtheme="mpt"] #login-wrapper .MuiInputBase-root input {
            background-color: transparent;
            /*padding-left: 16.5px !important;*/
        }

    [data-emdtheme="mpt"] #login-wrapper > form .MuiInputBase-root input::placeholder {
        color: #000 !important;
    }

    [data-emdtheme="mpt"] #login-wrapper .MuiInputAdornment-positionStart {
        /*display: none;*/
    }

/* TopAppBar */
[data-emdtheme="mpt"] #emdroot-wrapper > .MuiAppBar-root {
    margin: 0;
    left: 0;
    width: 100%;
}

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiAppBar-root > .MuiToolbar-root {
        padding-left: calc(5.25rem + 2px);
        padding-right: 2.5rem;
        border: none;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiAppBar-root .MuiBreadcrumbs-li,
    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiAppBar-root .MuiBreadcrumbs-separator {
        font-weight: bold;
    }

/* BottomBar */
[data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root {
    margin: 1em auto;
    overflow: auto;
    max-width: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: .75rem;
}

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root > .MuiPaper-root {
        background-color: #000;
        border-radius: 10px;
        padding: .33rem 0;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiToolbar-root {
        min-height: auto;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root > .MuiPaper-root,
    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root nav {
        display: flex;
        flex-direction: row;
        width: auto;
        border: none;
        overflow-y: hidden;
        gap: 1.25rem;
    }

        [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root > .MuiPaper-root > li:last-child .MuiListItemIcon-root,
        [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .logo {
            margin: 0 1.75rem !important;
        }

        [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root > .MuiPaper-root > li:last-child .MuiSvgIcon-root {
            height: 2.5rem;
            width: 2.5rem;
        }

        [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root nav .MuiListItemIcon-root .MuiSvgIcon-root {
            height: auto;
            width: auto;
        }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .logo {
        height: 3.75rem !important;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root hr {
        border-right: .5px solid #bbb;
        margin-top: .125em;
        margin-bottom: .125em;
        opacity: .5
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiList-root {
        padding: .25rem 0;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiListItem-padding {
        padding: 0;
        display: none !important;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiListItem-root {
        display: flex;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiListItemIcon-root {
        margin: 0;
        opacity: 1;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiListItemButton-root {
        padding: .5rem;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root svg path {
        color: #fff;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiListItemText-root > .MuiTypography-root,
    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiTypography-root > span,
    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root h5 + button {
        display: none;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiListItem-root.active {
        position: relative;
    }

        [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root .MuiListItem-root.active::after {
            content: ".";
            font-size: 2.5rem;
            color: #fff;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: -1rem;
        }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root h5 {
        border-right: 1px solid #BBBBBB;
        margin-right: 4rem;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper > .MuiDrawer-root > .MuiPaper-root > .MuiListItem-root:last-child > div {
        border-left: 1px solid #BBBBBB;
        margin-left: 2.75rem;
        padding: 0 .75rem;
        height: 100%;
    }
/* Main */
[data-emdtheme="mpt"] #emdroot-wrapper main {
    overflow: hidden;
}

/* DataView */
[data-emdtheme="mpt"] #emdroot-wrapper .data-view {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
    border-radius: .5rem;
    background-color: #F9F8F8;
    border: 1px solid #B8B8B8;
    padding: 1.25rem;
    overflow: auto hidden;
    align-items: flex-start;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .data-view > *:not(:last-child) {
        display: flex;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .data-view > * {
        height: auto;
        width: auto;
        flex: none;
        max-height: 100%;
    }

/* DataTable */
[data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper {
    gap: 1.25rem;
    align-items: flex-start;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper > * {
        border: 1px solid #B8B8B8;
        border-radius: .5rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-self: stretch;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper > .MuiPaper-root:not(.table-groups) {
        min-width: 350px;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .title {
        display: flex;
        margin-right: auto;
        padding-right: .66em;
        align-items: center;
        gap: .25em;
    }

[data-emdtheme="mpt"] #emdroot-wrapper > main:not([data-path-route="dataview"]) .data-view > .datatable-wrapper > hr,
[data-emdtheme="mpt"] #emdroot-wrapper > main:not([data-path-route="dataview"]) .data-view > .datatable-wrapper thead {
    display: none;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .MuiToolbar-root {
        border-bottom: 1px solid #B8B8B8;
        background-color: #F4F4F4;
        padding: 0 1.5rem;
    }

        [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .MuiToolbar-root + * {
            background-color: #fff;
            /*border-radius: .5rem;*/
        }

    [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .MuiButton-colorSuccess {
        background-color: #669966;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .MuiTableCell-sizeMedium .MuiIconButton-root {
        width: 1.225rem;
        height: 1.225rem;
        padding: 0;
    }

[data-emdtheme="mpt"] #emdroot-wrapper .groups-table {
    min-width: 20%;
    max-width: 50%;
}
/*
[data-emdtheme="mpt"] #emdroot-wrapper .MuiTableContainer-root {
    padding-right: 16px;
}*/

[data-emdtheme="mpt"] #emdroot-wrapper .MuiTableRow-root {
    position: relative;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .MuiTableRow-root > td:first-child {
        padding-left: 0;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .MuiTableRow-root.Mui-selected {
        background-color: transparent;
    }

        [data-emdtheme="mpt"] #emdroot-wrapper .MuiTableRow-root.Mui-selected > td:first-child::before {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            border: .19rem solid #666666;
            border-radius: 50%;
            box-shadow: 0px 1px 4px 0px #00000040;
            top: calc(50% - .25em);
            left: -1em;
        }

[data-emdtheme="mpt"] #emdroot-wrapper .MuiTableBody-root .MuiTableRow-root:last-child > .MuiTableCell-root {
    border-bottom: none;
}

[data-emdtheme="mpt"] #emdroot-wrapper .MuiTableRow-hover:hover {
    background-color: transparent;
}

/* LIST */
[data-emdtheme="mpt"] #emdroot-wrapper .MuiListItem-root.Mui-selected {
    background-color: #DFDFDF;
}

/* Dialog */
[data-emdtheme="mpt"] .MuiDialog-root .MuiButtonBase-root {
    color: #fff;
}
[data-emdtheme="mpt"] .MuiDialog-root .MuiButton-colorSuccess {
    background-color: #669966;
}
[data-emdtheme="mpt"] .MuiDialog-root .MuiButton-colorWarning {
    background-color: #ed6c02;
}
[data-emdtheme="mpt"] .MuiDialog-root .MuiButton-colorError {
    background-color: #FF0032;
}

[data-emdtheme="mpt"] .MuiDialog-root.location-dialog .MuiButton-colorError {
    display: none;
}
[data-emdtheme="mpt"] .MuiDialog-root.location-dialog .MuiDialogActions-root {
    position: absolute;
    right: 0;
}

[data-emdtheme="mpt"] .MuiDialog-root.remove-dialog .MuiPaper-root {
    width: 8em;
}
    [data-emdtheme="mpt"] .MuiDialog-root.remove-dialog .MuiPaper-root .MuiDialogActions-root {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 2em;
    }

/* DataForm */
[data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper {
    border: 1px solid #B8B8B8;
    border-radius: .5rem;
    overflow: hidden;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper .MuiToolbar-root {
        border-bottom: 1px solid #B8B8B8;
        background-color: #F4F4F4;
    }

        [data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper .MuiToolbar-root ~ * {
            background-color: #fff;
            border-radius: .5rem;
        }

        /* Toolbar */
        [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .MuiButton-root,
        [data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper .MuiToolbar-root > .MuiButton-root,
        [data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper .dataform-body .MuiButton-outlined,
        [data-emdtheme="mpt"] .MuiDialog-root .MuiButton-root,
        [data-emdtheme="mpt"] #emdroot-wrapper [data-page="license-menu"] .dataform-body .MuiButton-root {
            border-radius: 50%;
            font-size: 0;
            width: 1.5rem;
            height: 1.5rem;
            min-width: 1em;
            padding: 0;
        }

            [data-emdtheme="mpt"] .MuiDialog-root .MuiButton-root *,
            [data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .MuiButton-root *,
            [data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper .MuiToolbar-root .MuiButton-root *,
            [data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper .MuiButton-outlined *,
            [data-emdtheme="mpt"] #emdroot-wrapper [data-page="license-menu"] .dataform-body .MuiButton-outlined * {
                padding: 0;
                margin: 0;
            }

    [data-emdtheme="mpt"] #emdroot-wrapper .dataform-wrapper .MuiButton-outlinedSuccess * {
        color: #336633;
    }

[data-emdtheme="mpt"] #emdroot-wrapper .dataform-body {
    padding-top: 1em;
    padding-bottom: 1em;
    flex: 1;
}

[data-emdtheme="mpt"] #emdroot-wrapper .data-view > .dataform-wrapper {
    min-width: 650px;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .data-view > .dataform-wrapper:not(:first-child) {
        flex: 1;
    }

/* URL FIeld*/
[data-emdtheme="mpt"] #emdroot-wrapper .url-field-wrapper {
    align-items: center;
}
[data-emdtheme="mpt"] #emdroot-wrapper .url-field-wrapper > div:empty {
    display: none;
}
[data-emdtheme="mpt"] #emdroot-wrapper .url-field-wrapper .url-field {
    margin-left: 1rem;
}

/* Button */
[data-emdtheme="mpt"] #emdroot-wrapper .dataform-body .MuiToolbar-root .MuiButton-outlined,
[data-emdtheme="mpt"] #emdroot-wrapper .dataform-body .MuiToolbar-root .MuiButton-outlined *,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiToolbar-root label.MuiButton-outlined,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiToolbar-root label.MuiButton-outlined *,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiButton-contained,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiButton-contained * {
    color: #fff;
    margin: 0;
}

[data-emdtheme="mpt"] #emdroot-wrapper .datatable-wrapper .MuiIconButton-root,
[data-emdtheme="mpt"] #emdroot-wrapper header .MuiIconButton-root {
    border-width: 1px;
    border-style: solid;
    box-shadow: 0px 1px 4px 0px #00000029;
}

[data-emdtheme="mpt"] #emdroot-wrapper .MuiChip-root {
    border: 1px solid #B8B8B8;
    border-radius: .5rem;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .MuiChip-root.MuiChip-colorDefault {
        background-color: #F4F4F4;
    }

[data-emdtheme="mpt"] #emdroot-wrapper .location-breadcrumbs .MuiIconButton-root {
    width: 1.5rem;
    height: 1.5rem;
    padding:0;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .location-breadcrumbs .MuiIconButton-root > svg {
        font-size: 1rem;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .location-breadcrumbs .MuiIconButton-root path {
        color: rgba(0, 0, 0, 0.54);
    }

[data-emdtheme="mpt"] #emdroot-wrapper .MuiToggleButtonGroup-grouped,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiButtonGroup-grouped {
    padding: .15rem .75rem;
    font-weight: 700;
    border-radius: .5rem;
}

[data-emdtheme="mpt"] #emdroot-wrapper .MuiToggleButtonGroup-firstButton,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiToggleButtonGroup-middleButton,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiButtonGroup-firstButton,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiButtonGroup-middleButton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

[data-emdtheme="mpt"] #emdroot-wrapper .MuiToggleButtonGroup-lastButton,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiToggleButtonGroup-middleButton,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiButtonGroup-lastButton,
[data-emdtheme="mpt"] #emdroot-wrapper .MuiButtonGroup-middleButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

[data-emdtheme="mpt"] #emdroot-wrapper .MuiTreeItem-label * {
    font-size: 1rem;
}

/* Pages.Dashboards */
/* Pages.Sources */
[data-emdtheme="mpt"] #emdroot-wrapper .check-ds-wrapper {
    align-self: baseline;
}

    [data-emdtheme="mpt"] #emdroot-wrapper .check-ds-wrapper .check-ds-body {
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: .5em;
        margin-top: 1em;
        padding: 0 1em;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .check-ds-wrapper > .MuiButtonBase-root {
        background-color: #669966;
        border-radius: 10px;
    }

/* Pages.Users */
[data-emdtheme="mpt"] #emdroot-wrapper [data-page="users-menu"] .dataform-body > div:first-child {
    flex-direction: row;
}

[data-emdtheme="mpt"] #emdroot-wrapper [data-page="users-menu"] .dataform-body .MuiListItemButton-root {
    padding-left: 0;
}

    [data-emdtheme="mpt"] #emdroot-wrapper [data-page="users-menu"] .dataform-body .MuiListItemButton-root .MuiSwitch-root {
        margin-left: 0;
    }

        [data-emdtheme="mpt"] #emdroot-wrapper [data-page="users-menu"] .dataform-body .MuiListItemButton-root .MuiListItemIcon-root {
            min-width: 0;
        }

/* Pages.Roles */
[data-emdtheme="mpt"] #emdroot-wrapper .permissions .MuiList-root {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: .5em;
    margin-top: 1em;
}
    [data-emdtheme="mpt"] #emdroot-wrapper .permissions .MuiList-root:empty {
        display: none;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .permissions .MuiList-root .MuiChip-root {
        display: none;
    }

    [data-emdtheme="mpt"] #emdroot-wrapper .permissions .MuiList-root .MuiButtonBase-root:not(.Mui-selected) {
        border: none;
        box-shadow: none;
        background-color: transparent !important;
    }

[data-emdtheme="mpt"] #emdroot-wrapper .permissions > div {
    border: none;
    padding: 0 1rem;
}

[data-emdtheme="mpt"] #emdroot-wrapper .permissions > div:first-child {
    padding-left: 0;
}

/* Pages.Settings */
[data-emdtheme="mpt"] #emdroot-wrapper [data-page="settings-menu"] .dataform-body .MuiBox-root {
    flex-direction: column;
}

/* Pages.License */
[data-emdtheme="mpt"] #emdroot-wrapper [data-page="license-menu"] .dataform-body .license-params {
    border: 1px solid #B8B8B8;
    padding: .5rem 1rem 0;
    border-radius: 8px;
}
    [data-emdtheme="mpt"] #emdroot-wrapper [data-page="license-menu"] .dataform-body .license-params > .MuiBox-root {
        display: grid;
        grid-template-columns: auto auto;
        gap: 0;
    }
        [data-emdtheme="mpt"] #emdroot-wrapper [data-page="license-menu"] .dataform-body .license-params > .MuiBox-root > * {
            margin: 0;
            padding: 1rem;
            border-top: 1px solid #B8B8B8;
            text-align: center;
        }
            [data-emdtheme="mpt"] #emdroot-wrapper [data-page="license-menu"] .dataform-body .license-params > .MuiBox-root > *:nth-child(2n+1):not(:last-child) {
                border-right: 1px solid #B8B8B8;
            }