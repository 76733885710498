@import "../index.css";

#login-wrapper {
    align-self: center;
    align-items: stretch;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#login-form {
    display: flex;
    width: 420px;
    padding: 0 16px 36px;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 32px);
    gap: 16px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

    #login-form fieldset {
        border: 1px solid #2A2A2A;
    }

    #login-form input::placeholder {
        opacity: 1;
    }